@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
}

.minWidth iframe {
  width: 100%;
  flex: 1;
  margin: 0 auto;
  min-height: 800px;
}

.active {
  font-weight: 600;
  font-style: italic;
  color: #0a6d67;
}

.cuteBorder {
  border-right: 1px solid #c1c1c1;
  
}

.premium-required-tooltip {
  position: absolute;
  color: #0a6d67;
  padding: 5px;
  border-radius: 5px;
  top: -35px; /* Posiziona il tooltip sopra il bottone */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  white-space: nowrap; /* Impedisce il testo di andare a capo */
  display: inline; /* Mostra le due parole su una singola riga */
}

.cursor-not-allowed:hover .premium-required-tooltip {
  opacity: 1;
  visibility: visible;
}

.MuiBox-root > div > button.MuiButton-contained{
  background-color: rgb(10, 57, 69)!important;
  color:#ffffff!important;
}

.MuiBox-root > div > button.MuiButton-text{
  color: rgb(10, 57, 69)!important;
}

.MuiBox-root > div > button.Mui-disabled{
  color: rgba(0, 0, 0, 0.26)!important;
}

ul.customNavLeft li a{
  cursor: pointer;
}